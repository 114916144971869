.header {
  text-align: center;
}
.header > div {
  display: flex;
  width: 100px;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin: 8px auto;
  cursor: pointer;
  font-size: 18px;
  border: 1px solid #262b3b;
  border-radius: 10px;
  font-weight: bold;
  background-color: #f3f3f3;
  box-sizing: border-box;
}
.header > div:hover {
  background-color: #20ab46;
}

.container {
  display: flex;
}
.container .editor {
  min-width: 500px;
  min-height: 500px;
  width: 50% !important;
  height: calc(100vh - 66px) !important;
}
.container .output {
  width: 50%;
  padding: 8px;
  height: calc(100vh - calc(66px + 20px)) !important;
}
.container .output > div {
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  background-color: #0d101e;
  border-radius: 10px;
  padding: 12px;
  box-sizing: border-box;
}
.container .output > div > p {
  margin: 0;
  color: white;
  font-size: 16px;
}
.container .output > div > p.error {
  color: red;
}